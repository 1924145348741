import React from 'react'

import { graphql, useStaticQuery, Link } from 'gatsby'

import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar"

const Portal = () => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(sort: {fields: date}) {
                nodes {
                    title
                    date(formatString: "DD MMMM, YYYY")
                    slug
                }
            }

            file(relativePath: { eq: "portal.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)


    return (
        <Layout>

            <SEO title="Portal" />

            <Navbar />

            <div>

                {/* Background image */}
                <Img fluid={data.file.childImageSharp.fluid} 
                    style={{
                        position: 'fixed', height: '100vh', width: '100vw'
                    }} 
                />

                <ul className="columns" style={{padding: '20px 0'}}>
                    {
                        data.allContentfulBlogPost.nodes.sort().map(({title, date, slug}, index) => 
                            <li key={index} className="column is-one-third portal-list">
                                <Link to={`/portal/${slug}`}>
                                    <div className="portal-circle">
                                        <p className="is-size-5">{title}</p>
                                        <p>{date}</p>
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
        </Layout>
    )
}

export default Portal
